























































































































.handle__bar{
  cursor: ew-resize !important;
}
