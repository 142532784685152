// @import url("https://fonts.googleapis.com/css?family=Fira+Sans+Extra+Condensed:300,400,600&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Courgette&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "~vuetify/dist/vuetify.min.css";
@import "~@mdi/font/css/materialdesignicons.css";

@font-face {
  font-family: "Mirai";
  src: url("./fonts/Mirai-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Mirai";
  src: url("./fonts/Mirai-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Mirai";
  src: url("./fonts/Mirai-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Mirai";
  src: url("./fonts/Mirai-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Mirai";
  src: url("./fonts/Mirai-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Mirai";
  src: url("./fonts/Mirai-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Mirai";
  src: url("./fonts/Mirai-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Mirai";
  src: url("./fonts/Mirai-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Mirai";
  src: url("./fonts/Mirai-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Mirai";
  src: url("./fonts/Mirai-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

.v-data-table__wrapper {
  &::-webkit-scrollbar {
    margin-top: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(126, 124, 124, 0.76);
    // border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(243, 239, 239, 0.726);
  }

  table tr td {
    padding: 10px 5px;
  }
}

.better-scrollbar {
  //Chrome and updated browsers Only
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(126, 124, 124, 0.76);
    // border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(243, 239, 239, 0.726);
  }
}

body {
  font-family: "Mirai", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
th {
  font-family: "Mirai", sans-serif;
  font-weight: 100;
}

strong {
  font-family: "Mirai", sans-serif;
  font-weight: 500;
}

p,
a,
td,
span,
li,
ol,
.v-list-item,
.v-toolbar__title,
.v-card__title,
.v-text-field,
.v-footer {
  font-family: "Mirai", sans-serif;
  font-weight: normal;
}

b {
  font-family: "Mirai", sans-serif;
  font-weight: bold;
}

small,
blockquote,
.v-tab {
  font-family: "Mirai", sans-serif;
  font-weight: 300;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to /* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.selected-sidebar {
  background-color: #fff !important;
  .v-list-item__avatar .v-icon,
  .v-list-item__title {
    color: var(--v-primary-base) !important;
  }
}

.selected-sidebar-dark,
.selected-sidebar-dark:before {
  background-color: var(--v-secondary-base) !important;

  .v-list-item__avatar .v-icon,
  .v-list-item__title {
    color: #fff !important;
  }
}

.v-timeline-item__dot {
  background: rgba(255, 255, 255, 0.8) !important;
}

.snackbar-transition {
  transition: all 500ms ease-in-out;
}

.selected span table tr td {
  color: #fff;
}

.unit-info-table {
  font-weight: 200 !important;
  font-size: 12px;
  text-align: center;
  width: 33%;
}

.v-btn:not(.v-btn--icon) {
  span {
    font-family: "Mirai", sans-serif !important;
    font-weight: bold !important;
    font-size: 16px !important;
  }

  &.v-size--default {
    height: 50px !important;
    border-radius: 25px !important;
    padding: 0 30px 0 30px !important;
  }

  &.v-size--small {
    height: 45px !important;
    border-radius: 22.5px !important;
    padding: 0 20px 0 20px !important;
  }

  // &.v-btn--rounded {
  //   border-radius: 25px !important;
  // }

  &.primary {
    &:not(.v-btn--is-elevated, .btn--header):hover {
      box-shadow: 0 3px 12px rgba(158, 68, 43, 0.3),
        0 8px 20px rgba(255, 109, 55, 0.3);
    }

    &:active {
      box-shadow: none !important;
    }
  }

  &.v-btn--has-bg.primary {
    transition-duration: 0.28s;
    transition-property: background;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    &:hover,
    :active {
      background: #ff580f !important;
    }
  }

  &.v-btn--outlined.primary--text {
    transition-duration: 0.28s;
    transition-property: color, border-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    border: solid 1px !important;

    &:hover,
    :active:not(span, .v-icon) {
      border: solid 2px #ff580f !important;
      span {
        color: #ff580f !important;
      }
    }
  }

  &:before {
    background: transparent !important;
  }

  &:not(.btn--header):hover {
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0px) !important;
  }
}

.v-card,
.v-dialog {
  border-radius: 10px !important;
}

.v-picker {
  border-radius: 0 !important;
}

.v-text-field--outlined.v-input--dense.datetime-input .v-label {
  top: 14px !important;
  &.v-label--active {
    top: 10px !important;
  }
}

.v-text-field--outlined.v-input--dense.datetime-input.datetime--dense .v-label {
  top: 13px !important;
  &.v-label--active {
    top: 10px !important;
  }
}

.datetime-input .v-input__prepend-inner {
  margin-top: 12px !important;
}

.datetime-input.datetime--dense .v-input__prepend-inner {
  margin-top: 11px !important;
}

.phone-input {
  .country-code > .v-input {
    border-top-right-radius: 0 !important;
    margin-right: 2px !important;
    border-bottom-right-radius: 0 !important;
  }

  & > .v-input > .v-input__control {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
